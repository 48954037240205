import { useState } from "react";
import { useGetAccountInfo, useGetLoginInfo } from "@multiversx/sdk-dapp/hooks";
import { logout } from "@multiversx/sdk-dapp/utils";

import "./index.css";
import Modal from "react-modal";
import UnlockPage from "../../../pages/unlock";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import CyclockLogo from "./../../../assets/svg/logo.svg";
import { LogoTrusties } from "../../../assets";
import { HashLink } from "react-router-hash-link";

function Header() {
  const { address } = useGetAccountInfo();
  const { isLoggedIn } = useGetLoginInfo();
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const [isLoginMenuOpen, setIsLoginMenuOpen] = useState<boolean>(false);

  const handleLogout = () => {
    logout(`${window.location.origin}`);
  };

  const openMenu = () => {
    setIsMenuOpen(true);
  };
  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  const closeLoginMenu = () => {
    setIsLoginMenuOpen(false);
  };

  const truncAddr = (nbLeadChar: number) => {
    return (
      <p>
        {address.slice(0, nbLeadChar) +
          "..." +
          address.slice(address.length - nbLeadChar)}
      </p>
    );
  };

  return (
    <>
      <Modal
        isOpen={isLoginMenuOpen}
        onRequestClose={closeLoginMenu}
        className="login-modal"
        overlayClassName="login-overlay"
        ariaHideApp={false}
      >
        <UnlockPage></UnlockPage>
      </Modal>
      <header className="flex justify-between p-5 md:p-16">
        <div className="flex items-center text-black w-full justify-between">
          <img alt="logo" src={LogoTrusties} className="md:mr-16 w-16" />

          <div className="hidden lg:flex lg:justify-start gap-0 lg:gap-10 lg:w-full lg:items-center">
            {/*<p className="text-lg cursor-pointer font-light">HOME</p>*/}
            <HashLink
              className="text-lg cursor-pointer font-light hover:border-b-blue hover:border-b"
              smooth
              to="/#scientist"
            >
              TEAM
            </HashLink>
            <HashLink
              className="text-lg cursor-pointer font-light hover:border-b-blue hover:border-b"
              smooth
              to="/#partners"
            >
              PARTNERS
            </HashLink>
          </div>

          <div className="inline-flex items-center">
            <div className="hidden md:flex">
              <a
                className="py-3 px-8 ml-6 tracking-widest border-2 border-black rounded-2xl hover:text-white hover:bg-black duration-200"
                href="https://www.frameit.gg/marketplace/XTRUSTIES-740a21/mint?sp=true"
              >
                MINT
              </a>
            </div>
            {isLoggedIn ? (
              <span className="lg:hidden mr-3">{truncAddr(8)}</span>
            ) : (
              <></>
            )}
            <img
              alt="logo"
              src={CyclockLogo}
              className="md:ml-6 w-12 hover:rotate-90 duration-200 cursor-pointer"
              onClick={openMenu}
            />
          </div>
        </div>

        {isMenuOpen ? (
          <div className="fixed h-full w-full lg:w-4/12 z-50 top-0 left-0 flex flex-col items-center bg-[#ffffffcf] lg:bg-white backdrop-blur-md lg:backdrop-blur-none md:shadow-xl">
            <FontAwesomeIcon
              onClick={closeMenu}
              className="self-end mt-8 mr-8 text-3xl cursor-pointer"
              icon={faXmark}
            />
            {isLoggedIn ? (
              <>
                <span className="text-3xl md:text-xl mt-9">{truncAddr(8)}</span>
                <button className="bg-white border-4 md:border-2 border-black text-4xl md:text-xl mt-9 rounded-2xl w-10/12 py-6 md:py-3 font-light">
                  PROFILE
                </button>
              </>
            ) : (
              <>
                <a
                  className="bg-white border-4 md:border-2 border-black text-4xl md:text-xl mt-9 rounded-2xl w-10/12 py-6 md:py-3 font-light text-center"
                  href="https://www.frameit.gg/marketplace/XTRUSTIES-740a21/mint?sp=true"
                >
                  MINT
                </a>
              </>
            )}
            <a
              href="https://discord.com/invite/9RkE9utY3D"
              className="bg-white border-4 md:border-2 border-black text-4xl md:text-xl mt-9 rounded-2xl w-10/12 py-6 md:py-3 font-light text-center"
            >
              DISCORD
            </a>
            <h1 className="w-10/12 text-4xl mt-10 font-bold">EARN $TRST</h1>
            <span className="border-2 lg:border border-blue w-10/12 mt-3"></span>
            <p className="text-grey text-2xl w-10/12 mt-3">COMING SOON</p>
            <h1 className="w-10/12 text-4xl mt-10 font-bold">SPEND $TRST</h1>
            <span className="border-2 lg:border border-blue w-10/12 mt-3"></span>
            <p className="text-grey text-2xl w-10/12 mt-3">COMING SOON</p>
            {isLoggedIn ? (
              <button
                className="bg-white border-4 md:border-2 border-black text-4xl md:text-xl mt-9 rounded-2xl w-10/12 py-6 md:py-3 font-light"
                onClick={handleLogout}
              >
                DISCONNECT
              </button>
            ) : (
              <></>
            )}
          </div>
        ) : (
          <></>
        )}
      </header>
    </>
  );
}

export default Header;
