import { EnvironmentsEnum } from "@multiversx/sdk-dapp/types";

export const dAppName = "TRUSTIES";

export const environment = EnvironmentsEnum.devnet;

// Generate your own WalletConnect 2 ProjectId here: https://cloud.walletconnect.com/app
export const walletConnectV2ProjectId = "f7439bbc3e9b13ab4cacfd4c2c315c3d";
export const networkConfig = {
  name: "customConfig",
  apiTimeout: 6000,
  walletConnectV2ProjectId,
};

export const scAddress =
  "erd1qqqqqqqqqqqqqpgqj8m8q7eezt0fmvn734077meqzd53kfsnsu5s60x3zj";

export const apiUrl = "https://devnet-api.multiversx.com";
export const chainID = "D";
