import withPageTitle from "./components/PageTitle";
import { dAppName } from "./config";
import Home from "./pages/home";

export const routeNames = {
  home: "/",
};

const routes: Array<any> = [
  {
    path: routeNames.home,
    title: "HOME",
    component: Home,
  }
];

const mappedRoutes = routes.map((route) => {
  const title = route.title ? `${dAppName} • ${route.title}` : dAppName;

  const requiresAuth = Boolean(route.authenticatedRoute);
  const wrappedComponent = withPageTitle(title, route.component);

  return {
    path: route.path,
    component: wrappedComponent,
    authenticatedRoute: requiresAuth,
  };
});

export default mappedRoutes;
