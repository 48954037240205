import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
} from "react-router-dom";
import "./App.css";
import { DappProvider } from "@multiversx/sdk-dapp/wrappers";
import {
  TransactionsToastList,
  SignTransactionsModals,
  NotificationModal,
} from "@multiversx/sdk-dapp/UI";
import { environment, networkConfig } from "./config";
import Layout from "./components/Layout";
import routes from "./routes";

function App() {
  return (
    <Router>
      <DappProvider
        environment={environment}
        customNetworkConfig={networkConfig}
      >
        <Layout>
          <NotificationModal />
          <SignTransactionsModals />
          <TransactionsToastList />
          <Routes>
            {routes.map((route: any, index: number) => (
              <Route
                path={route.path}
                key={"route-key-" + index}
                element={<route.component />}
              />
            ))}
            <Route path="*" element={<Navigate to="/" replace />} />
          </Routes>
        </Layout>
      </DappProvider>
    </Router>
  );
}

export default App;
