import { AuthenticatedRoutesWrapper } from "@multiversx/sdk-dapp/wrappers";
import { FC, ReactNode } from "react";
import Header from "./Header";
import routes from "../../routes";
import "./index.css";
import Footer from "./Footer";

interface Props {
  children: ReactNode;
}

const Layout: FC<Props> = ({ children }) => {

  return (
    <div className="flex flex-col h-full">
      <Header />
      <main className="grow flex flex-col">
        <AuthenticatedRoutesWrapper routes={routes} unlockRoute={`/`}>
          {children}
        </AuthenticatedRoutesWrapper>
      </main>
      <Footer />
    </div>
  );
};

export default Layout;
