function Scientists(props: any) {

  return (
    <div className="flex flex-col items-center w-5/12 md:w-1/2 mx-3">
      <img alt={props.name} src={props.img} className=" w-72 pl-6 pr-4"/>
      <p className="text-blue text-md lg:text-lg">@{props.twitter}</p>
      <h1 className="text-xl lg:text-4xl font-gobold lg:mb-4">{props.name}</h1>
      <h1 className="text-xl lg:text-4xl font-gobold mb-4">{props.role}</h1>
    </div>
  );
}

export default Scientists;
