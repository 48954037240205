import "./index.css";


function Footer() {

  return (
    <div className="flex justify-center mb-5">
        <p className="text-grey text-sm">© 2022 xTrusties, All Rights Reserved</p>
    </div>
  );
}

export default Footer;