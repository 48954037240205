import "./index.css";
import TrustiesSVG from "./../../assets/svg/trusties.svg";
import {
  BlueSeparator,
  DenisPNG,
  Horizon,
  LogoDiscord,
  LogoTrusties,
  LogoTwitter,
  MehdiPNG,
  MintStakeDesktop,
  MintStakeMobile,
  NicoPNG,
  RamzyPNG,
  Roadmap1,
  Roadmap2,
  Roadmap3,
} from "../../assets";
import RoadmapElement from "./roadmap-element";
import Scientists from "./scientists";
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";

function Home() {
  return (
    <div className="mb-20">
      <div className="flex flex-col lg:flex-row px-5 lg:pl-28 w-full gap-5 lg:gap-0">
        <div
          id="trusties"
          className="mt-0 lg:mt-16 flex flex-col items-center lg:w-6/12 lg:block"
        >
          <img
            alt="Grey title"
            src={TrustiesSVG}
            className="w-80 my-10 lg:mb-4"
          />
          <h1 className="relative w-fit text-5xl font-gobold mb-4 bg-blue-square text-center lg:text-left lg:before:w-[37rem]">
            WE VERIFY – YOU EARN
          </h1>
          <p className="text-grey">
            xTrusties is the creation of 500 Trusties by FOUR scientists who
            wanted to bring Security to the MultiversX ecosystem. After the
            Great Rug-Pull Pandemic, which lasted more than 30 years and during
            which they remained locked in their factory, the scientists decided
            to create xTrusties: 500 robots programmed to ensure a secure future
            for MultiversX.
          </p>
        </div>
        <div className="lg:w-5/12">
          <img src={process.env.PUBLIC_URL + "/assets/asset-1.png"} alt="" />
        </div>
      </div>
      <div id="mint" className="flex justify-center w-100 mt-10 mx-5">
        <div className="text-center lg:w-7/12 mt-4">
          <h1 className="relative text-6xl font-gobold mb-4">
            MINT, STAKE &{" "}
            <span className="bg-blue-square before::w-0 lg:before:w-[8rem]">
              EARN
            </span>
          </h1>
          <p className="text-grey lg:px-16">
            Minting a xTrusties not only helps to secure the MultiversX
            ecosystem but also grants access to passive $EGLD rewards. To combat
            rug pulls and earn rewards, scientists have developed the Staking
            Station that recharges the robots and enhances their fighting
            abilities while generating passive $EGLD rewards for the holders.
          </p>
        </div>
      </div>
      <div id="stak" className="flex flex-col items-center w-full">
        <img
          src={MintStakeDesktop}
          className="hidden md:block w-9/12 z-10"
          alt="Mint & Stake"
        />
        <img
          src={MintStakeMobile}
          className="block md:hidden w-7/12 z-10 mt-5"
          alt="Mint & Stake"
        />
        <img
          src={BlueSeparator}
          alt=""
          className="max-w-full w-full absolute z-0 translate-y-[400px] md:translate-y-3/4"
        />
        <button
          id="btnStakeNft"
          className="relative top-[80px] lg:top-[140px] border border-black bg-grey md:bg-blue hover:bg-grey w-72 px-10 py-5 text-xl font-light z-10"
        >
          STAKE YOUR NFT
        </button>
        <Tooltip anchorId="btnStakeNft" place="top">
          COMING SOON
        </Tooltip>
        <img
          src={process.env.PUBLIC_URL + "/assets/separator.png"}
          alt=""
          className="w-full mt-24"
        />
      </div>
      <div id="roadmap" className="flex flex-col items-center mt-8">
        <h1 className="text-4xl lg:text-6xl font-gobold mb-6">OUR ROADMAP</h1>
        <div className="lg:flex lg:w-11/12 mt-0 lg:mt-12">
          <div className="block lg:hidden relative lg:static left-60 top-16 w-40 lg:w-6/12">
            <img src={Roadmap1} className="w-40 lg:mt-20" alt="" />
          </div>
          <div className="flex flex-col items-start px-6 lg:px-0 lg:w-6/12">
            <h1 className="relative text-6xl font-gobold mb-8 self-start lg:self-center bg-blue-square before:w-[13rem]">
              PHASE 1
            </h1>
            <RoadmapElement
              title="DOXXING SERVICE"
              text="A service brought to the MultiversX by xTrusties, allowing more founders to get doxxed through a real KYC program, reducing the rug-pull opportunities."
            />
            <RoadmapElement
              title="PRESALE"
              text="A presale of 500 Trusties, only for Early Visitors (OG) and WLs members."
            />
            <RoadmapElement
              title="WALLET SUBMISSION"
              text="A discord bot tied to our website making the submission and the retrieval of the $EGLD addresses of members having access to a specific mint easier and more interactive."
            />
          </div>
          <div className="hidden lg:block lg:w-6/12">
            <img src={Roadmap1} className="w-full px-32 lg:mt-20" alt="" />
          </div>
        </div>
        <div className="lg:flex flex-col-reverse lg:flex-row lg:w-11/12 pt-0 lg:pt-16 mb-0 lg:mb-8">
          <div className="relative lg:static left-56 top-16 w-40 lg:w-6/12">
            <img
              src={Roadmap2}
              className="w-full px-0 lg:px-32 lg:mt-20"
              alt=""
            />
          </div>
          <div className="flex flex-col items-start px-6 lg:px-0 lg:w-6/12">
            <h1 className="relative text-6xl font-gobold mb-8 self-start lg:self-center bg-blue-square before:w-[13rem]">
              PHASE 2
            </h1>
            <RoadmapElement
              title="STAKING"
              text="Stake your Trusties to earn $TRST."
            />
            <RoadmapElement
              title="TASK2EARN"
              text="Complete missions related to affiliated projects to earn $TRST"
            />
            <RoadmapElement
              title="TRUSTTHETRUSTIES"
              text="The TTT program will include every tools and services offered by Trusties and a more important place in the TRUSTshop and the Task2Earn system."
            />
            <RoadmapElement
              title="PUBLIC SALES"
              text="Two distinct public sales of 759 Trusties with a more advantageous price for Early Visitors and WLs."
            />
          </div>
        </div>
        <div className="lg:flex lg:w-11/12 pt-0 lg:pt-8 -mt-10 lg:-mt-0">
          <div className="block lg:hidden relative lg:static left-60 top-20 w-40 lg:w-6/12">
            <img src={Roadmap3} className="w-40 lg:mt-20" alt="" />
          </div>
          <div className="flex flex-col items-start px-6 lg:px-0 lg:w-6/12">
            <h1 className="relative text-6xl font-gobold mb-8 self-start lg:self-center bg-blue-square before:w-[13rem]">
              PHASE 3
            </h1>
            <RoadmapElement
              title="TRUST INDEX"
              text="The implementation of a safety index allowing MultiversX users to know about the safety of a project. The rating of the projects will be made with the information related to security/transparency available about it."
            />
            <RoadmapElement
              title="EXPORTATION"
              text="The implementation of our tools and services on blockchains requiring security in the field of NFTs"
            />
            <RoadmapElement
              title="FINAL SALE"
              text="A final sale of 759 Trusties will be launched."
            />
          </div>
          <div className="hidden lg:block lg:w-6/12">
            <img src={Roadmap3} className="w-full px-32 lg:mt-20" alt="" />
          </div>
        </div>
        <img
          src={process.env.PUBLIC_URL + "/assets/separator.png"}
          alt=""
          className="w-full my-6 lg:my-0"
        />
      </div>
      <div id="scientist" className="flex flex-col items-center mt-8">
        <h1 className="text-4xl lg:text-6xl font-gobold mb-4">
          MEET THE SCIENTISTS
        </h1>
        <div className="flex justify-center md:justify-between flex-wrap lg:flex-nowrap mt-8">
          <Scientists
            img={RamzyPNG}
            twitter="RamzyNFT"
            name="RAMZY"
            role="ARTIST DIRECTOR"
          />
          <Scientists
            img={MehdiPNG}
            twitter="MehdiTrusties"
            name="MEHDI BOUTOUBAT"
            role="FOUNDER"
          />
          <Scientists
            img={DenisPNG}
            twitter="KreativeDeniss"
            name="DENISS RENNER"
            role="FOUNDER"
          />
          <Scientists
            img={NicoPNG}
            twitter="NicoEGLD"
            name="NICOLAS LANG"
            role="FOUNDER"
          />
        </div>
        <div id="partners" className="flex flex-col items-center pt-16 mt-4">
          <h1 className="text-6xl font-gobold">PARTNERS</h1>
          <p className="text-grey my-10 px-3 lg:px-0">
            Discover our partners, they are the ones who make it possible for us
            to offer you the best tools and services.
          </p>
          <img src={Horizon} className=" w-8/12 mt-16" alt="" />
        </div>
        <img
          src={process.env.PUBLIC_URL + "/assets/separator.png"}
          alt=""
          className="w-full my-6 lg:my-0"
        />
      </div>
      <div id="socials" className="flex flex-col justify-center items-center">
        <div className="flex flex-col lg:flex-row items-center lg:items-start lg:w-7/12 mb-16 gap-5 lg:gap-0">
          <div className="flex flex-col justify-start items-center lg:items-start lg:w-1/2">
            <h2 className="text-5xl lg:text-6xl font-gobold mb-8">FOLLOW US</h2>
            <div className="flex gap-3 mb-8">
              <a href="https://discord.com/invite/9RkE9utY3D">
                <img alt="Logo Discord" src={LogoDiscord} className="w-4" />
              </a>
              <a href="https://twitter.com/xTrusties">
                <img alt="Logo Twitter" src={LogoTwitter} className="w-4" />
              </a>
            </div>
            <img src={LogoTrusties} className="w-20" alt="" />
          </div>
          <img
            src={process.env.PUBLIC_URL + "/assets/socials.png"}
            alt=""
            className="w-[80%] lg:w-1/2"
          />
        </div>
        <h1 className="text-4xl lg:text-7xl font-gobold mb-4 lg:w-1/2 text-center px-3 lg:px-0">
          JOIN XTRUSTIES FOR A SAFER ECOSYSTEM
        </h1>
      </div>
    </div>
  );
}

export default Home;
